// TurboBoost.Commands.logger.level = 'debug'

TurboBoost.Commands.registerEventDelegate('debounced:keyup', [
  'input[data-turbo-command][data-turbo-command-event=keyup]',
])

const changeSelectors = [
  'input[data-turbo-command]:not([data-turbo-command-event=keyup])',
  'select[data-turbo-command]:not([data-turbo-command-event=keyup])',
  'textarea[data-turbo-command]:not([data-turbo-command-event=keyup])',
]

TurboBoost.Commands.registerEventDelegate('change', changeSelectors)

document.addEventListener('mouseover', (event) => {
  const el = event.target.closest('[data-turbo-command]')
  if (!el) return
  if (!el.dataset.bubblingMouseEnterInitialized) {
    el.dataset.bubblingMouseEnterInitialized = true
    el.addEventListener('mouseleave', () => {
      el.mouseAbove = false
    })
  }
  if (el.mouseAbove) return
  el.mouseAbove = true
  el.dispatchEvent(new CustomEvent('mouseabove', { bubbles: true }))
})

// eslint-disable-next-line
TurboBoost.Commands.registerEventDelegate('mouseabove', [
  'turbo-boost-toggle-trigger[data-event="mouseover"][data-turbo-command]',
])

// Special handler for Select2 elements that aren't SuperSelects (used on the SmartFunnel wizard)
// TODO: Remove this if/when those Select2 elements are replaced with SuperSelects
TurboBoost.Commands.registerEventDelegate('cf:change', ['select[data-turbo-command][data-action^="cf:change"]'])

TurboBoost.Commands.registerEventDelegate('action-cable-context:refresh', ['meta[name="action-cable-context"]'])
