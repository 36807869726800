import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['ring', 'label']
  static values = {
    runOnConnect: { type: Boolean, default: true },
    itemsSelector: String,
    completedItemsSelector: String,
    labelPrefix: String,
    labelSuffix: String,
  }

  connect() {
    if (!this.runOnConnectValue) return

    this.update()
  }

  update() {
    const progress = this.calculateProgress()

    this.ringTarget.value = progress

    if (!this.hasLabelTarget) return

    this.labelTarget.innerText = `${this.labelPrefixValue}${progress}${this.labelSuffixValue}`
  }

  calculateProgress() {
    return Math.round((this.completedItems / this.totalItems) * 100)
  }

  get totalItems() {
    return this.element.querySelectorAll(this.itemsSelectorValue).length
  }

  get completedItems() {
    return this.element.querySelectorAll(this.completedItemsSelectorValue).length
  }
}
