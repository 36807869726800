import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    timeout: { type: Number, default: 0 },
  }

  initialize() {
    this.debounceTimer = null
  }

  attachFormData(event) {
    console.log('Attaching form data')
    clearTimeout(this.debounceTimer)

    this.debounceTimer = setTimeout(() => {
      this.element.dataset.form = this.formJSON
      this.emitFormUpdatedEvent()
    }, this.timeoutValue)
  }

  get form() {
    return this.element.closest('form')
  }

  get formData() {
    if (!this.form) return {}
    return Object.fromEntries(new FormData(this.form))
  }

  get formJSON() {
    return JSON.stringify(this.formData)
  }

  emitFormUpdatedEvent() {
    const event = new CustomEvent('formUpdated', { bubbles: true, cancelable: true })
    this.element.dispatchEvent(event)
  }
}
